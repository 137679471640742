import React, { FC, useState } from 'react'
import styled from 'styled-components'
import Modal from './Modal'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { message } from 'antd'

const CredentialsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  textarea {
    background-color: #fff2d1;
    border: none;
    border-radius: 0.5rem;
    width: 100%;
    padding: 0.5rem 0.8rem;
    outline: none;

    ::placeholder {
      color: #d48941;
    }

    &.blur {
      filter: blur(3px);
    }
  }

  .description {
    padding: 0 0.5rem;
    color: #c15a00;
    font-family: Jua;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .dog-btns {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
  }
`

const DogButton = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin: 1rem 0 0;
  box-shadow: none;
  width: 100px;

  font-size: 1.2rem;
  color: #fff;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #d18d00;
  font-family: Jua;
  font-style: normal;
  font-weight: 400;
  line-height: 0;
  white-space: nowrap;

  position: relative;

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    gap: 5px;

    span {
      margin-top: 2px;
    }
  }
`

interface CredentialsProps {
  show: boolean
  onClose: () => void
  phrase: string
}

const Credentials: FC<CredentialsProps> = ({ show, onClose, phrase }) => {
  const [blur, setBlur] = useState<boolean>(true)

  const handleClose = () => {
    setBlur(true)
    onClose()
  }

  return (
    <Modal show={show} onClose={handleClose}>
      <div className={'modal-header'}>Credentials</div>
      <CredentialsWrapper>
        <div className='description'>Seedphrase</div>
        <textarea readOnly={true} name='keyphrase' rows={4} className={blur ? 'blur' : ''} value={phrase}></textarea>

        <div className={'dog-btns'}>
          <DogButton onClick={() => setBlur((prev) => !prev)}>
            <img src='/images/home/gold-btn.png' alt='View' />
            <div>
              <svg height={12} viewBox='0 0 28 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M6.37491 15.4625C8.1449 16.4345 10.2263 17.0518 12.4025 17.2179C17.5691 17.6123 23.0738 15.4349 25.7938 9.56449C24.3335 7.541 22.8724 5.98388 21.4343 4.81334L18.426 6.94067C18.8287 7.7045 19.0566 8.57477 19.0566 9.49827C19.0566 12.5314 16.5978 14.9902 13.5647 14.9902C11.9717 14.9902 10.5372 14.312 9.53399 13.2286L6.37491 15.4625ZM4.28707 15.5121L1.57886 17.4272L2.25147 18.3783L5.32809 16.2027C7.37525 17.4308 9.81777 18.1889 12.3138 18.3795C17.9608 18.8105 24.0903 16.3673 26.996 9.73204L27.1279 9.43068L26.938 9.16207C25.4468 7.05343 23.9342 5.39204 22.4195 4.11665L25.5505 1.90258L24.8779 0.951416L21.4659 3.36421C20.5511 2.69024 19.6371 2.15233 18.7279 1.73702C15.9895 0.486138 13.331 0.364097 10.902 0.943562C6.07848 2.09426 2.20156 5.99479 0.25271 9.19546L0.12793 9.40039L0.183689 9.63375C0.756178 12.0297 2.26238 14.0287 4.28707 15.5121ZM5.29554 14.799C3.37085 13.478 1.94511 11.6901 1.37584 9.59455C3.2642 6.59858 6.8523 3.1073 11.1723 2.07672C13.365 1.55364 15.7564 1.66043 18.2438 2.79666C18.9724 3.12945 19.7122 3.55197 20.4597 4.0757L17.7762 5.97332C16.7688 4.77093 15.256 4.00635 13.5647 4.00635C10.5316 4.00635 8.07275 6.46517 8.07275 9.49827C8.07275 10.5195 8.35148 11.4756 8.837 12.2946L5.29554 14.799ZM16.821 6.64881C16.0279 5.74319 14.8631 5.1713 13.5647 5.1713C11.175 5.1713 9.23771 7.10855 9.23771 9.49827C9.23771 10.2689 9.43915 10.9924 9.79225 11.6191L16.821 6.64881ZM10.4957 12.5485C11.2794 13.3371 12.365 13.8252 13.5647 13.8252C15.9544 13.8252 17.8916 11.888 17.8916 9.49827C17.8916 8.82554 17.7381 8.18867 17.4642 7.62078L10.4957 12.5485Z'
                  fill='#CC6606'
                />
              </svg>
              <span>View</span>
            </div>
          </DogButton>
          <CopyToClipboard onCopy={() => message.success('Copied successful', 2)} text={phrase}>
            <DogButton>
              <img src='/images/home/green-btn.png' alt='View' />
              <div>
                <svg height={14} viewBox='0 0 22 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M5.61013 3.37387C5.61013 2.52013 6.29452 1.82804 7.13875 1.82804H14.5503V5.34129C14.5503 6.86767 15.7739 8.10505 17.2832 8.10505H19.9236V18.4574C19.9236 19.3112 19.2392 20.0033 18.395 20.0033H7.13875C6.29452 20.0033 5.61013 19.3112 5.61013 18.4574V3.37387ZM15.7546 5.34129V2.04977L19.8146 6.88712H17.2832C16.439 6.88712 15.7546 6.19503 15.7546 5.34129ZM4.41805 3.11011C4.54937 1.70745 5.71734 0.610107 7.13875 0.610107H16.126L21.128 6.56985V18.4574C21.128 19.9838 19.9044 21.2212 18.395 21.2212H16.836C16.6949 22.6253 15.5143 23.7212 14.0788 23.7212H2.89931C1.36872 23.7212 0.12793 22.4753 0.12793 20.9385V5.89284C0.12793 4.35598 1.36872 3.11011 2.89931 3.11011H4.41805ZM15.6033 21.2212H7.13875C5.62936 21.2212 4.40576 19.9838 4.40576 18.4574V4.3364H2.89931C2.04321 4.3364 1.34921 5.03324 1.34921 5.89284V20.9385C1.34921 21.7981 2.04321 22.4949 2.89931 22.4949H14.0788C14.8387 22.4949 15.4709 21.9458 15.6033 21.2212Z'
                    fill='#24550B'
                  />
                </svg>
                <span>Copy</span>
              </div>
            </DogButton>
          </CopyToClipboard>
        </div>
      </CredentialsWrapper>
    </Modal>
  )
}

export default Credentials
