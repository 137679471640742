import { Carousel } from 'antd'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AppState } from 'state'
import styled from 'styled-components'
import { addCommasToNumber } from 'utils'
import { Box, Flex, Skeleton } from 'widgets'
import Account from 'widgets/Poms/Account'
import QRModalBody from 'widgets/Poms/QRModalBody'
import { Content } from './HomeGame'
import Transfer from 'widgets/Poms/Transfer'

export const Wrapper = styled.div`
  padding: 0 1rem 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const BalanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Balance = styled.div`
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.6);
  font-family: Jua;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  color: #ee8110;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
`

const TransferButton = styled(Box)`
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin: 0 auto;
  padding: 0.3rem 0.8rem 0.3rem 0.3rem;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  * {
    color: #fff;
    -webkit-text-stroke-width: 1;
    -webkit-text-stroke-color: #000;
    font-family: Jua;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: linear-gradient(270deg, #40ed9a 0%, #128c85 100%);
    width: 24px;
    height: 24px;
    border: 1px solid #fff;

    img {
      width: 12px;
    }
  }
`

const RewardCard = styled.div`
  .card-body {
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  .badge {
    position: relative;
    top: -15px;
    right: -10px;
    width: 90px;
    z-index: 1;
  }

  .card {
    width: calc(100% - 50px);
    background-size: cover;
    background: url('/images/poms/reward-bg.png') center;
    border-radius: 1.5rem;
    padding: 0.75rem 1rem;
    border: 2px solid #423fa3;
    position: relative;
    margin: -80px auto 0;
    min-height: 150px;

    .title {
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
      font-family: Jua;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #f8be01;
    }

    .description {
      color: #fff;
      font-family: Jua;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .point {
      display: flex;
      align-items: center;
      gap: 0.7rem;
      margin: 0.5rem 0;

      span {
        color: #fff;
        font-family: Jua;
        font-size: 1.7rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      img {
        width: 40px;
      }
    }

    .claim {
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 0.5rem;

      span {
        color: #fff;
        font-family: Jua;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
`

export const CustomCarousel = styled(Carousel)`
  .slick-dots {
    padding-top: 24px;
    bottom: -10px;
    z-index: 2;

    li {
      height: 6px;
      width: 6px;

      button {
        height: 6px;
        width: 6px;
        border-radius: 4px;
        background-color: #569fb5;
      }
    }

    li.slick-active {
      height: 6px;
      width: 6px;

      button {
        height: 6px;
        width: 6px;
        border-radius: 4px;
        background-color: #007cee;
      }
    }
  }
`

const Title = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;

  .title {
    color: #fff;
    font-family: Jua;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0 3rem;
    border-bottom: 2px solid #fff;
  }
`

const Tokens = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 0 1rem;

  * {
    color: #fff;
    font-family: Jua;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.5rem;

    img {
      width: 40px;
    }

    .ton {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: #118ccc;
      width: 40px;
      height: 40px;
      padding: 4px;

      img {
        width: 24px;
      }
    }
  }
`

const Header = ({ setShowAccountModal, setShowQRModal }) => {
  return (
    <div className={'layout-header port-page-header'}>
      <div onClick={() => setShowAccountModal((prev) => !prev)}>
        <img src='/images/home/menu.png' alt='menu' />
      </div>
      <div onClick={() => setShowQRModal((prev) => !prev)}>
        <img src='/images/home/qr.png' alt='qr' />
      </div>
    </div>
  )
}

const Dashboard = () => {
  const history = useHistory()
  const { userBalance: balances, tokenInfo } = useSelector((state: AppState) => state.wallet)

  const [showAccountModal, setShowAccountModal] = useState(false)
  const [showQRModal, setShowQRModal] = useState(false)
  const [showTransfer, setShowTransfer] = useState(false)

  return (
    <>
      <Header setShowAccountModal={setShowAccountModal} setShowQRModal={setShowQRModal} />
      <div className={'layout-content'}>
        <Wrapper>
          <Content>
            <BalanceWrapper>
              <Balance>
                {balances?.loading || !tokenInfo ? (
                  <Skeleton width={100} height={40} my='5px' />
                ) : (
                  `$${addCommasToNumber(+balances?.ton * +tokenInfo?.priceUsd, 3)}`
                )}
              </Balance>
              <TransferButton onClick={() => setShowTransfer((prev) => !prev)}>
                <div>
                  <img src='/images/tokens/ton.png' alt='ton' />
                </div>
                <span>Transfer</span>
              </TransferButton>
            </BalanceWrapper>
            <CustomCarousel swipeToSlide dots={true} slidesToShow={1} infinite={false}>
              <RewardCard>
                <div className={'card-body'}>
                  <img src='/images/poms/badge.png' alt='badge' className='badge' />
                  <div className='card'>
                    <div className='title'>POMERZ GAME</div>
                    <div className='description'>Play games and search for POMC</div>
                    <div className='point'>
                      <img src='/images/home/paw.png' alt='point' />

                      {balances?.loading ? (
                        <Skeleton width={100} height={34} />
                      ) : (
                        <span>{addCommasToNumber(+balances?.pom, 3)}</span>
                      )}
                    </div>
                    <Box className='claim' onClick={() => history.push('/game')}>
                      <span>Claim now</span>
                      <svg xmlns='http://www.w3.org/2000/svg' height='12' viewBox='0 0 43 30' fill='none'>
                        <path
                          d='M2 13C0.895431 13 9.65645e-08 13.8954 0 15C-9.65645e-08 16.1046 0.89543 17 2 17L2 13ZM42.4142 16.4142C43.1953 15.6332 43.1953 14.3668 42.4142 13.5858L29.6863 0.857867C28.9052 0.076818 27.6389 0.0768178 26.8579 0.857867C26.0768 1.63892 26.0768 2.90525 26.8579 3.68629L38.1716 15L26.8579 26.3137C26.0768 27.0948 26.0768 28.3611 26.8579 29.1421C27.6389 29.9232 28.9052 29.9232 29.6863 29.1421L42.4142 16.4142ZM2 17L41 17L41 13L2 13L2 17Z'
                          fill='white'
                        />
                      </svg>
                    </Box>
                  </div>
                </div>
              </RewardCard>

              {[1, 2].map((x) => (
                <RewardCard key={x}>
                  <div className={'card-body'}>
                    <img src='/images/poms/badge.png' alt='badge' className='badge' />
                    <div className='card'>
                      <div className='title'>Coming soon</div>
                    </div>
                  </div>
                </RewardCard>
              ))}
            </CustomCarousel>
            <Title>
              <div className='title'>Tokens</div>
            </Title>
            <Tokens>
              <div className='title'>Your Portfolio</div>

              <Flex width='100%' flexDirection='column'>
                <div className='item'>
                  <img src='/images/home/paw.png' alt='pom' />
                  <div>
                    <div>POMC</div>
                    <div>
                      {balances?.loading ? (
                        <Skeleton width={50} height={18} minHeight='auto' />
                      ) : (
                        addCommasToNumber(+balances?.pom, 3)
                      )}
                    </div>
                  </div>
                </div>

                <div className='item'>
                  <div className='ton'>
                    <img src='/images/tokens/ton.png' alt='ton' />
                  </div>
                  <div>
                    <div>TON</div>
                    <div>
                      {balances?.loading ? (
                        <Skeleton width={50} height={18} minHeight='auto' />
                      ) : (
                        addCommasToNumber(+balances?.ton, 3)
                      )}
                    </div>
                  </div>
                </div>
              </Flex>
            </Tokens>
          </Content>
        </Wrapper>
        <Account show={showAccountModal} onClose={() => setShowAccountModal((prev) => !prev)} />
        <QRModalBody show={showQRModal} onClose={() => setShowQRModal((prev) => !prev)} />
        <Transfer show={showTransfer} onClose={() => setShowTransfer((prev) => !prev)} />
      </div>
    </>
  )
}

export default Dashboard
