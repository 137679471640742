import { message } from 'antd'
import useAuthentication from 'hooks/useAuthentication'
import { FC, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useHistory } from 'react-router-dom'
import { storageUserData } from 'services/curl/Auth'
import styled from 'styled-components'
import { Skeleton } from 'widgets/Skeleton'
import Modal from './Modal'

const CreateAccountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  button[type='submit'] {
    border-radius: 62px;
    border: none;
    border-top: 2px solid #00fe0a;
    border-bottom: 2px solid #005b61;
    background: linear-gradient(180deg, #abff40 0%, #00bee8 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 16.5px 2px #37ff87 inset;
    color: #4c7904;
    font-family: Jua;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0.8rem;
    margin-top: 1rem;

    :disabled {
      opacity: 0.5;
    }
  }

  textarea {
    background-color: #fff2d1;
    border: none;
    border-radius: 0.5rem;
    width: 100%;
    padding: 0.5rem 0.8rem;
    outline: none;

    ::placeholder {
      color: #d48941;
    }

    &.blur {
      filter: blur(3px);
    }
  }

  .description {
    padding: 0 0.5rem;
  }

  .dog-btns {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
  }
`

const TextAreaWithCopyButton = styled.div`
  position: relative;

  textarea {
    padding-right: 50px;
  }

  button {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    box-shadow: none;
    padding: 0;
    margin: 0;
  }
`

const DogButton = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin: 1rem 0 0;
  box-shadow: none;
  width: 100px;

  font-size: 1.2rem;
  color: #fff;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #d18d00;
  font-family: Jua;
  font-style: normal;
  font-weight: 400;
  line-height: 0;
  white-space: nowrap;

  position: relative;

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    gap: 5px;

    span {
      margin-top: 2px;
    }
  }
`

interface CreateAccountProps {
  show: boolean
  onClose: () => void
  walletData: {
    walletAddress: string
    mnemonic: string[]
  } | null
}

const CreateAccount: FC<CreateAccountProps> = ({ show, onClose, walletData }) => {
  const history = useHistory()
  const { login } = useAuthentication()

  const [blur, setBlur] = useState<boolean>(true)
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    setBlur(true)
    onClose()
  }

  const handleConfirm = async () => {
    if (!walletData) return
    setLoading(true)

    await login(
      walletData.walletAddress,
      (token) => {
        storageUserData(walletData, token)
        setLoading(false)
        handleClose()
        setTimeout(() => history.push('/home'), 300)
      },
      () => setLoading(false)
    )
  }

  return (
    <Modal show={show} onClose={handleClose}>
      <div className={'modal-header'}>Create Account</div>
      <CreateAccountWrapper>
        <div className='description'>
          <b>Address</b>
          <div>We have created a unique TON address for you</div>
        </div>
        {walletData ? (
          <TextAreaWithCopyButton>
            <textarea readOnly={true} name='keyphrase' rows={2} value={walletData?.walletAddress}></textarea>
            <CopyToClipboard onCopy={() => message.success('Copied successful', 2)} text={walletData?.walletAddress}>
              <button>
                <svg width='20' viewBox='0 0 40 46' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M10.442 5.26431C10.442 3.63814 11.7456 2.31987 13.3537 2.31987H27.4708V9.01178C27.4708 11.9192 29.8015 14.2761 32.6765 14.2761H37.7058V33.9949C37.7058 35.6211 36.4022 36.9394 34.7941 36.9394H13.3537C11.7456 36.9394 10.442 35.6211 10.442 33.9949V5.26431ZM29.7649 9.01178V2.74221L37.4981 11.9562H32.6765C31.0685 11.9562 29.7649 10.638 29.7649 9.01178ZM13.3537 0C10.4786 0 8.14795 2.35691 8.14795 5.26431V6.66675H5.27882C2.36341 6.66675 0 9.03984 0 11.9672V40.6256C0 43.5529 2.36341 45.926 5.27882 45.926H26.573C29.4884 45.926 31.8519 43.5529 31.8519 40.6256V39.4577H29.5256V40.6256C29.5256 42.2629 28.2037 43.5902 26.573 43.5902H5.27882C3.64816 43.5902 2.32626 42.2629 2.32626 40.6256V11.9672C2.32626 10.3299 3.64816 9.00254 5.27882 9.00254H8.14795V33.9949C8.14795 36.9023 10.4786 39.2593 13.3537 39.2593H34.7941C37.6691 39.2593 39.9998 36.9023 39.9998 33.9949V11.3519L30.4723 0H13.3537Z'
                    fill='#CC6606'
                  />
                </svg>
              </button>
            </CopyToClipboard>
          </TextAreaWithCopyButton>
        ) : (
          <Skeleton height={55} backGroundColor='#fcde92' />
        )}

        <div className='description'>
          <b>Seedphrase</b>
          <div>Copy your seed phrase right now to avoid losing your account!</div>
        </div>

        {walletData ? (
          <TextAreaWithCopyButton>
            <textarea
              readOnly={true}
              name='keyphrase'
              rows={5}
              className={blur ? 'blur' : ''}
              value={walletData?.mnemonic}
            ></textarea>
          </TextAreaWithCopyButton>
        ) : (
          <Skeleton height={94} backGroundColor='#fcde92' />
        )}

        <div className={'dog-btns'}>
          <DogButton onClick={() => setBlur((prev) => !prev)}>
            <img src='/images/home/gold-btn.png' alt='View' />
            <div>
              <svg height={12} viewBox='0 0 28 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M6.37491 15.4625C8.1449 16.4345 10.2263 17.0518 12.4025 17.2179C17.5691 17.6123 23.0738 15.4349 25.7938 9.56449C24.3335 7.541 22.8724 5.98388 21.4343 4.81334L18.426 6.94067C18.8287 7.7045 19.0566 8.57477 19.0566 9.49827C19.0566 12.5314 16.5978 14.9902 13.5647 14.9902C11.9717 14.9902 10.5372 14.312 9.53399 13.2286L6.37491 15.4625ZM4.28707 15.5121L1.57886 17.4272L2.25147 18.3783L5.32809 16.2027C7.37525 17.4308 9.81777 18.1889 12.3138 18.3795C17.9608 18.8105 24.0903 16.3673 26.996 9.73204L27.1279 9.43068L26.938 9.16207C25.4468 7.05343 23.9342 5.39204 22.4195 4.11665L25.5505 1.90258L24.8779 0.951416L21.4659 3.36421C20.5511 2.69024 19.6371 2.15233 18.7279 1.73702C15.9895 0.486138 13.331 0.364097 10.902 0.943562C6.07848 2.09426 2.20156 5.99479 0.25271 9.19546L0.12793 9.40039L0.183689 9.63375C0.756178 12.0297 2.26238 14.0287 4.28707 15.5121ZM5.29554 14.799C3.37085 13.478 1.94511 11.6901 1.37584 9.59455C3.2642 6.59858 6.8523 3.1073 11.1723 2.07672C13.365 1.55364 15.7564 1.66043 18.2438 2.79666C18.9724 3.12945 19.7122 3.55197 20.4597 4.0757L17.7762 5.97332C16.7688 4.77093 15.256 4.00635 13.5647 4.00635C10.5316 4.00635 8.07275 6.46517 8.07275 9.49827C8.07275 10.5195 8.35148 11.4756 8.837 12.2946L5.29554 14.799ZM16.821 6.64881C16.0279 5.74319 14.8631 5.1713 13.5647 5.1713C11.175 5.1713 9.23771 7.10855 9.23771 9.49827C9.23771 10.2689 9.43915 10.9924 9.79225 11.6191L16.821 6.64881ZM10.4957 12.5485C11.2794 13.3371 12.365 13.8252 13.5647 13.8252C15.9544 13.8252 17.8916 11.888 17.8916 9.49827C17.8916 8.82554 17.7381 8.18867 17.4642 7.62078L10.4957 12.5485Z'
                  fill='#CC6606'
                />
              </svg>
              <span>View</span>
            </div>
          </DogButton>
          <CopyToClipboard
            onCopy={() => walletData?.mnemonic && message.success('Copied successful', 2)}
            text={walletData?.mnemonic}
          >
            <DogButton>
              <img src='/images/home/green-btn.png' alt='View' />
              <div>
                <svg height={14} viewBox='0 0 22 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M5.61013 3.37387C5.61013 2.52013 6.29452 1.82804 7.13875 1.82804H14.5503V5.34129C14.5503 6.86767 15.7739 8.10505 17.2832 8.10505H19.9236V18.4574C19.9236 19.3112 19.2392 20.0033 18.395 20.0033H7.13875C6.29452 20.0033 5.61013 19.3112 5.61013 18.4574V3.37387ZM15.7546 5.34129V2.04977L19.8146 6.88712H17.2832C16.439 6.88712 15.7546 6.19503 15.7546 5.34129ZM4.41805 3.11011C4.54937 1.70745 5.71734 0.610107 7.13875 0.610107H16.126L21.128 6.56985V18.4574C21.128 19.9838 19.9044 21.2212 18.395 21.2212H16.836C16.6949 22.6253 15.5143 23.7212 14.0788 23.7212H2.89931C1.36872 23.7212 0.12793 22.4753 0.12793 20.9385V5.89284C0.12793 4.35598 1.36872 3.11011 2.89931 3.11011H4.41805ZM15.6033 21.2212H7.13875C5.62936 21.2212 4.40576 19.9838 4.40576 18.4574V4.3364H2.89931C2.04321 4.3364 1.34921 5.03324 1.34921 5.89284V20.9385C1.34921 21.7981 2.04321 22.4949 2.89931 22.4949H14.0788C14.8387 22.4949 15.4709 21.9458 15.6033 21.2212Z'
                    fill='#24550B'
                  />
                </svg>
                <span>Copy</span>
              </div>
            </DogButton>
          </CopyToClipboard>
        </div>

        <button type='submit' onClick={handleConfirm} disabled={loading || !walletData}>
          Create
        </button>
      </CreateAccountWrapper>
    </Modal>
  )
}

export default CreateAccount
