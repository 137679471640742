import React, { FC, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { checkUserData, logout } from 'services/curl/Auth'
import styled from 'styled-components'
import Modal from './Modal'
import { useDispatch } from 'react-redux'
import { setUserInfo } from 'state/user/actions'
import { setUserBalance } from 'state/wallet/actions'

const LogoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  textarea {
    background-color: #fff2d1;
    border: 1px solid #de8718;
    border-radius: 0.5rem;
    width: 100%;
    padding: 0.5rem 0.8rem;

    ::placeholder {
      color: #d48941;
    }
  }

  button {
    border-radius: 62px;
    border: none;
    border-top: 2px solid #00fe0a;
    border-bottom: 2px solid #005b61;
    background: linear-gradient(180deg, #abff40 0%, #00bee8 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 16.5px 2px #37ff87 inset;
    color: #4c7904;
    font-family: Jua;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0.5rem 0.8rem;
    margin-top: 3rem;
    height: 46px;
  }

  .description {
    padding: 0 0.5rem;
  }
`

interface ConfirmLogoutProps {
  show: boolean
  onClose: () => void
}

const ConfirmLogout: FC<ConfirmLogoutProps> = ({ show, onClose }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const userData = checkUserData()
  const randomNumber = useMemo(() => Math.floor(Math.random() * 24) + 1, [show])
  const [phraseValue, setPhraseValue] = useState('')

  const handleConfirm = () => {
    const phraseArray = userData?.phrase?.split(' ')

    if (phraseArray[randomNumber - 1] !== phraseValue) {
      toast.error('Wrong Seed Phrase')
      return
    }
    dispatch(setUserInfo(undefined))
    dispatch(setUserBalance({ loading: true, ton: '0', pom: '0' }))
    toast.success('Logout success')
    logout()
    setTimeout(() => history.push('/'), 300)
  }

  return (
    <Modal show={show} onClose={onClose}>
      <div className={'modal-header'}>Confirm Logout</div>
      <LogoutWrapper>
        <div className={'description'}>
          Are you sure you want to log out of your account? Make sure you save the seed phrase, otherwise you will lose access to
          your account!
        </div>
        <textarea
          name='keyphrase'
          placeholder={`Enter word #${randomNumber} from your seed phrase`}
          rows={5}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setPhraseValue(event.target.value)}
        ></textarea>
        <button onClick={handleConfirm}>Logout</button>
      </LogoutWrapper>
    </Modal>
  )
}

export default ConfirmLogout
