import { formatCode } from 'helpers/CommonHelper'
import { FC, useState } from 'react'
import { checkUserData } from 'services/curl/Auth'
import styled from 'styled-components'
import ConfirmLogout from './ConfirmLogout'
import Credentials from './Credentials'
import Modal from './Modal'
import RecoverInviter from './RecoverInviter'
import { useSelector } from 'react-redux'
import { AppState } from 'state'
import { Skeleton } from 'widgets/Skeleton'
import { addCommasToNumber } from 'utils'
import { Box } from 'widgets/Box'
import ModalUpdateUserProfile from './ModalUpdateUserProfile'

const AccountBox = styled.div`
  display: flex;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
  background-color: #fff2d1;
  border-radius: 1.5rem;
  padding: 1rem;

  img {
    width: 20px;
    height: 20px;
  }

  .account-left {
    display: flex;
    align-items: center;
    gap: 1rem;

    color: rgba(193, 90, 0, 0.69);
    font-family: Jua;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-size: 0.8rem;

    img {
      width: 45px;
      height: 45px;
    }

    .title {
      color: #c15a00;
      font-family: Jua;
      font-size: 1.1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .subtitle {
      color: rgba(193, 90, 0, 0.8);
      font-family: Jua;
      font-size: 0.85rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`

const SettingBox = styled.div`
  margin-top: 1rem;
  padding: 0 1rem;

  .title {
    color: #c15a00;
    font-family: Jua;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 1rem;
  }

  .options {
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 1rem;
      gap: 1rem;

      color: #c15a00;
      font-family: Jua;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      & > div {
        display: flex;
        gap: 0.5rem;
        align-items: center;
      }

      img {
        width: 20px;
        height: 20px;
      }
    }
  }
`

const Button = styled.button`
  width: 100%;
  border-radius: 62px;
  border: none;
  border-top: 2px solid #00fe0a;
  border-bottom: 2px solid #005b61;
  background: linear-gradient(180deg, #abff40 0%, #00bee8 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 16.5px 2px #37ff87 inset;
  color: #4c7904;
  font-family: Jua;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 1rem 0.8rem;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  height: 46px;
`

interface AccountProps {
  show: boolean
  onClose: () => void
}

const Account: FC<AccountProps> = ({ show, onClose }) => {
  const userData = checkUserData()
  const { userBalance } = useSelector((state: AppState) => state.wallet)
  const { userInfo } = useSelector((state: AppState) => state.user)

  const [credentialsActive, setCredentialsActive] = useState(false)
  const [inviterActive, setInviterActive] = useState(false)
  const [logoutActive, setLogoutActive] = useState(false)
  const [editActive, setEditActive] = useState(false)

  return (
    <>
      <Modal show={show} onClose={onClose}>
        <div className={'modal-header'}>Account</div>
        <AccountBox>
          <div className={'account-left'}>
            <img src='/images/home/paw.png' alt='paw' />
            <div>
              <div className='title'>{userInfo?.fullname || 'Pomerz'}</div>
              <div>{userData ? formatCode(userData?.address, 10, 5) : '--'}</div>
              {userBalance?.loading ? (
                <Skeleton minHeight='auto' height={17} width={150} />
              ) : (
                <div className='subtitle'>
                  {addCommasToNumber(userBalance?.pom, 3)} POMC | {addCommasToNumber(userBalance?.ton, 3)} TON
                </div>
              )}
            </div>
          </div>
          <Box as='img' src='/images/home/pencil.png' alt='edit' onClick={() => setEditActive((prev) => !prev)} />
        </AccountBox>
        <SettingBox>
          <div className='title'>Settings</div>
          <div className='options'>
            <div className='option' onClick={() => setCredentialsActive((prev) => !prev)}>
              <div>
                <img src='/images/home/seed-phrase.png' alt='seed-phrase' />
                <div>Seed phrase</div>
              </div>
              <svg xmlns='http://www.w3.org/2000/svg' height='12' viewBox='0 0 13 23' fill='none'>
                <path
                  d='M2 2L10.3485 10.8123C10.7138 11.198 10.7138 11.802 10.3485 12.1877L2 21'
                  stroke='#C15A00'
                  strokeWidth='4'
                  strokeLinecap='round'
                />
              </svg>
            </div>
            <div className='option' onClick={() => setInviterActive((prev) => !prev)}>
              <div>
                <img src='/images/home/inviter.png' alt='inviter' />
                <div>Recover inviter</div>
              </div>
              <svg xmlns='http://www.w3.org/2000/svg' height='12' viewBox='0 0 13 23' fill='none'>
                <path
                  d='M2 2L10.3485 10.8123C10.7138 11.198 10.7138 11.802 10.3485 12.1877L2 21'
                  stroke='#C15A00'
                  strokeWidth='4'
                  strokeLinecap='round'
                />
              </svg>
            </div>
          </div>
        </SettingBox>
        <Button onClick={() => setLogoutActive((prev) => !prev)}>
          <svg xmlns='http://www.w3.org/2000/svg' width='16' viewBox='0 0 44 46' fill='none'>
            <path
              d='M16.1785 5.3869C15.7667 4.07032 14.3578 3.32392 13.0963 3.88219C9.09449 5.65324 5.69276 8.58711 3.35109 12.3287C0.565677 16.7794 -0.536166 22.0795 0.244614 27.2716C1.02539 32.4636 3.63731 37.2053 7.60852 40.6399C11.5797 44.0745 16.6483 45.9756 21.8987 45.9998C27.1491 46.0239 32.235 44.1696 36.2377 40.7717C40.2403 37.3738 42.8958 32.6564 43.7243 27.4717C44.5529 22.2871 43.4999 16.9771 40.7556 12.5009C38.4485 8.73791 35.0739 5.77285 31.0885 3.96502C29.8323 3.39516 28.4166 4.12856 27.9927 5.44129C27.5688 6.75401 28.3008 8.14575 29.5374 8.7572C32.3945 10.17 34.8133 12.3662 36.4968 15.112C38.618 18.5718 39.4319 22.676 38.7914 26.6834C38.151 30.6908 36.0985 34.337 33.0047 36.9634C29.911 39.5897 25.9799 41.023 21.9217 41.0043C17.8635 40.9856 13.9458 39.5162 10.8764 36.8615C7.80689 34.2068 5.78805 30.5418 5.18456 26.5287C4.58107 22.5156 5.43272 18.419 7.58566 14.9789C9.29434 12.2487 11.7333 10.0749 14.6033 8.68845C15.8454 8.08841 16.5902 6.70347 16.1785 5.3869Z'
              fill='#4C7904'
            />
            <rect x='20' width='4' height='13' rx='2' fill='#4C7904' />
          </svg>
          Log Out
        </Button>
      </Modal>
      <Credentials show={credentialsActive} onClose={() => setCredentialsActive((prev) => !prev)} phrase={userData?.phrase} />
      <RecoverInviter show={inviterActive} onClose={() => setInviterActive((prev) => !prev)} />
      <ConfirmLogout show={logoutActive} onClose={() => setLogoutActive((prev) => !prev)} />
      <ModalUpdateUserProfile show={editActive} onClose={() => setEditActive((prev) => !prev)} userInfo={userInfo} />
    </>
  )
}

export default Account
