import { Dropdown, InputNumber } from 'antd'
import { MIN_TON_FEE } from 'constants/index'
import { isTonAddress } from 'helpers/CommonHelper'
import { encryptData } from 'hooks/useTonMnemonic'
import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { checkUserData } from 'services/curl/Auth'
import useGameService from 'services/curl/Games'
import { AppState } from 'state'
import { setModalNotification } from 'state/modal/actions'
import styled from 'styled-components'
import { addCommasToNumber } from 'utils'
import { Box, Flex } from 'widgets/Box'
import { Text } from 'widgets/Text'
import Modal from './Modal'

const TransferWrapper = styled.div`
  .description {
    padding: 0 0.5rem;
  }

  .title {
    color: #c15a00;
    font-family: Jua;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    & + .title {
      font-size: 1rem;
    }
  }

  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 1.5rem;
    border: 1px solid #de8718;
    background: rgba(255, 231, 169, 0.54);
    padding: 0.5rem 0.8rem;
    margin-bottom: 1rem;
    min-height: 40px;
    gap: 0.25rem;

    &.select {
      padding: 0;
    }

    * {
      white-space: nowrap;
    }

    input {
      border: none;
      background: none;
      outline: none;
      font-family: Jua;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 70%;

      &::placeholder {
        color: rgba(193, 90, 0, 0.69);
      }
    }

    .ant-input-number {
      all: unset;
      width: 100%;

      input {
        padding: 0;
        width: 100%;
      }
    }

    img {
      width: 20px;
    }
  }

  .amount-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ant-select {
    width: 100%;

    .ant-select-selector {
      padding: 0;
      border: none !important;
      outline: none !important;
      background: transparent !important;
      box-shadow: none !important;

      align-items: center;
      height: 30px;

      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        color: rgba(193, 90, 0, 0.69);
      }
    }
  }

  .ant-dropdown-trigger {
    width: 100%;
  }
`

const Button1 = styled.button`
  width: 100%;
  border-radius: 62px;
  border: none;
  border-top: 2px solid #00fe0a;
  border-bottom: 2px solid #005b61;
  background: linear-gradient(180deg, #abff40 0%, #00bee8 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 16.5px 2px #37ff87 inset;
  color: #4c7904;
  font-family: Jua;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0.75rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  height: 46px;

  :disabled {
    opacity: 0.5;
  }
`

const Button2 = styled(Button1)`
  margin-top: 0.5rem;
  border-top: 2px solid #fecb00;
  border-bottom: 2px solid #cc6606;
  background: linear-gradient(180deg, #ffe040 0%, #e86100 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 16.5px 2px #ffc737 inset;
  color: #4c7904;
`

interface TransferProps {
  show: boolean
  onClose: () => void
}

const Transfer: FC<TransferProps> = ({ show, onClose }) => {
  const userData = checkUserData()
  const dispatch = useDispatch()
  const { fetchTransfer } = useGameService()

  const { userBalance, tokenInfo } = useSelector((state: AppState) => state.wallet)
  const [tokenSelected, setTokenSelected] = useState('')
  const [amount, setAmount] = useState<string | number>('')
  const [address, setAddress] = useState('')

  const handleClose = () => {
    setTokenSelected('')
    setAmount('')
    setAddress('')
    onClose()
  }

  const handleChoosePercent = (val: number) => {
    if (!tokenSelected) return
    setAmount(+userBalance[tokenSelected] * val)
  }

  const handleChangeAmount = (val: number) => {
    if (!tokenSelected) return
    if (val > userBalance[tokenSelected]) return setAmount(userBalance[tokenSelected])
    setAmount(val)
  }

  const renderButtonStatus = () => {
    if (!address) return 'Enter address'
    if (!isTonAddress(address?.trim())) return 'Wrong address'
    if (!tokenSelected) return 'Select token'
    if (!amount) return 'Enter amount'
    if (!+userBalance[tokenSelected] || +userBalance[tokenSelected] < +amount) return 'Balance influences'

    return 'Submit'
  }

  const handleSubmit = async () => {
    if (tokenSelected === 'pom' && +userBalance?.ton < MIN_TON_FEE) {
      dispatch(setModalNotification({ toggle: true, dataModal: { message: 'Min gas fee for transfer POMC 0.05TON' } }))
      return
    }

    dispatch(
      setModalNotification({
        toggle: true,
        dataModal: {
          message: 'Transferring',
          buttonDisabled: true,
          loading: true,
        },
      })
    )

    const payload = {
      mnemonic: encryptData(userData?.phrase),
      toAddress: address?.trim(),
      amount,
      isTon: tokenSelected === 'ton',
    }

    await fetchTransfer(
      payload,
      async () => {
        setTokenSelected('')
        setAmount('')
        setAddress('')
        dispatch(setModalNotification({ toggle: true, dataModal: { message: 'Transfer success!!!' } }))
      },
      () => {
        dispatch(setModalNotification({ toggle: true, dataModal: { message: 'Transfer failed' } }))
      }
    )
  }

  return (
    <Modal show={show} onClose={handleClose}>
      <div className={'modal-header'}>Transfer</div>
      <TransferWrapper>
        <div className='description'>Send token in an instant</div>
        <div className='title description'>Receiver</div>
        <div className='box'>
          <input
            type='text'
            placeholder={'Enter Address'}
            value={address}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAddress(event.target.value)}
          />
        </div>
        <div className='title description'>ASSET</div>
        <div className='box select'>
          <Dropdown
            trigger={['click']}
            overlay={
              <Flex
                flexDirection='column'
                background='#fff'
                mt='0.25rem'
                p='0.5rem'
                style={{ boxShadow: '1px 1px 5px #ccc', gap: 8 }}
                borderRadius={8}
              >
                <Text
                  style={{ width: '100%' }}
                  color='#de8718'
                  fontSize={14}
                  onClick={() => {
                    setTokenSelected('pom')
                    setAmount('')
                  }}
                >
                  POMC
                </Text>
                <Text
                  style={{ width: '100%' }}
                  color='#de8718'
                  fontSize={14}
                  onClick={() => {
                    setTokenSelected('ton')
                    setAmount('')
                  }}
                >
                  TON
                </Text>
              </Flex>
            }
          >
            <Box padding='0.25rem 0.8rem'>
              {!tokenSelected ? 'Choose token' : (tokenSelected === 'pom' ? 'POMC' : tokenSelected).toUpperCase()}
            </Box>
          </Dropdown>
        </div>
        <div className='description amount-block'>
          <div className='title'>Amount</div>
          <div className='title'>Balance: {tokenSelected ? addCommasToNumber(userBalance[tokenSelected], 3) : '--'}</div>
        </div>
        <div className='box'>
          {[0.25, 0.5, 0.75, 1].map((x) => (
            <Box key={x} onClick={() => handleChoosePercent(x)}>
              {x * 100}%
            </Box>
          ))}
        </div>
        <div className='box'>
          <InputNumber
            value={amount}
            controls={false}
            onChange={(val) => {
              handleChangeAmount(+val)
            }}
          />

          {!tokenSelected || tokenSelected === 'pom' ? (
            <></>
          ) : (
            <div>~ {tokenInfo ? addCommasToNumber((+amount || 0) * +tokenInfo?.priceUsd, 3) : '--'}$</div>
          )}
        </div>
      </TransferWrapper>
      <Button1 disabled={renderButtonStatus() !== 'Submit'} onClick={handleSubmit}>
        {renderButtonStatus()}
      </Button1>
      <Button2 onClick={handleClose}>Cancel</Button2>
    </Modal>
  )
}

export default Transfer
