import { Spin } from 'antd'
import useAuthentication from 'hooks/useAuthentication'
import useTonMnemonic from 'hooks/useTonMnemonic'
import React, { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { storageUserData } from 'services/curl/Auth'
import styled from 'styled-components'
import Modal from './Modal'

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  textarea {
    background-color: #fff2d1;
    border: 1px solid #de8718;
    border-radius: 0.5rem;
    width: 100%;
    padding: 0.5rem 0.8rem;

    ::placeholder {
      color: #d48941;
    }
  }

  button {
    border-radius: 62px;
    border: none;
    border-top: 2px solid #00fe0a;
    border-bottom: 2px solid #005b61;
    background: linear-gradient(180deg, #abff40 0%, #00bee8 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 16.5px 2px #37ff87 inset;
    color: #4c7904;
    font-family: Jua;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0.5rem 0.8rem;
    margin-top: 3rem;

    :disabled {
      opacity: 0.5;
    }
  }

  .description {
    padding: 0 0.5rem;
  }
`

interface LoginProps {
  show: boolean
  onClose: () => void
}

const Login: FC<LoginProps> = ({ show, onClose }) => {
  const history = useHistory()
  const { checkPhase } = useTonMnemonic()
  const { login } = useAuthentication()

  const [phase, setPhase] = useState('')
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    setPhase('')
    onClose()
  }

  const handleConfirm = async () => {
    setLoading(true)
    const wallet = await checkPhase(phase, () => {
      toast.error('Invalid phase. Please try again!')
      setLoading(false)
    })

    if (wallet) {
      await login(
        wallet.walletAddress,
        (token) => {
          storageUserData(wallet, token)
          setLoading(false)
          handleClose()
          setTimeout(() => history.push('/home'), 300)
        },
        () => setLoading(false)
      )
    }
  }

  return (
    <Modal show={show} onClose={handleClose}>
      <div className={'modal-header'}>Login</div>
      <LoginWrapper>
        <div className='description'>
          <b>Import</b>
          <div>Enter your seed phrase or private key to login</div>
        </div>
        <textarea
          name='keyphrase'
          value={phase}
          placeholder={'Seed phrase or Private Key'}
          rows={5}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setPhase(event?.target?.value)}
        ></textarea>
        <button disabled={loading} onClick={handleConfirm}>
          Continue {loading && <Spin />}
        </button>
      </LoginWrapper>
    </Modal>
  )
}

export default Login
