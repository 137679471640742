import useTonMnemonic from 'hooks/useTonMnemonic'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import CreateAccount from '../../widgets/Poms/CreateAccount'
import Login from '../../widgets/Poms/Login'

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url('/images/home/background.png');
  background-size: cover;
  background-position: center;
`

export const AuthWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;

  img {
    width: 100%;
    max-width: 250px;
  }

  button {
    outline: none;
    border: none;
    background: none;
    transition: transform 0.2s;

    &:hover {
      transform: scale(0.95);
    }

    > img {
      max-height: 50px;
    }
  }
`

const Auth = () => {
  const { generate } = useTonMnemonic()

  const [loginModalActive, setLoginModalActive] = useState<boolean>(false)
  const [signupModalActive, setSignupModalActive] = useState<boolean>(false)
  const [walletData, setWalletData] = useState<any>(null)

  useEffect(() => {
    generate().then((res) => setWalletData(res))
  }, [generate])

  return (
    <Wrapper>
      <AuthWrapper>
        <img src='/images/home/doge.png' alt='poms' />
        <button onClick={() => setLoginModalActive(true)}>
          <img src='/images/home/login-button.png' alt='login' />
        </button>
        <button onClick={() => setSignupModalActive(true)}>
          <img src='/images/home/signup-button.png' alt='signup' />
        </button>
      </AuthWrapper>
      <Login show={loginModalActive} onClose={() => setLoginModalActive(false)} />
      <CreateAccount show={signupModalActive} onClose={() => setSignupModalActive(false)} walletData={walletData} />
    </Wrapper>
  )
}

export default Auth
