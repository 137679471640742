import 'assets/css/index.css'
import ModalNotification from 'components/ModalCongratulation/ModalNotification'
import PrivateLayout from 'layouts/PrivateLayout'
import PublicLayout from 'layouts/PublicLayout'
import { Suspense } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { checkUserData, logout } from 'services/curl/Auth'
import { addToken } from 'services/curl/Axios'
import LoadingPage from 'widgets/Poms/LoadingPage'
import Auth from './Auth'
import Home from './Home'
import HomeGame from './Home/HomeGame'

export const isLogin = () => {
  const data = checkUserData()
  if (data?.address && data?.token) {
    addToken(data?.token)
    return true
  }

  logout()
  return false
}

export const PublicRoute = ({ component: Component, ...rest }) => {
  if (isLogin()) {
    return (
      <Redirect
        to={{
          pathname: '/home',
        }}
      />
    )
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <PublicLayout {...rest}>
          <LoadingPage />
          <Component {...props} />
        </PublicLayout>
      )}
    />
  )
}
export const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return isLogin() ? (
          <PrivateLayout {...rest}>
            <Component {...props} />
          </PrivateLayout>
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        )
      }}
    />
  )
}

function App() {
  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <ToastContainer />
        <Switch>
          <PublicRoute exact path='/' component={Auth} />
          <PrivateRoute exact path='/home' component={Home} />
          <PrivateRoute exact path='/game' component={HomeGame} />

          <Route path='*' component={() => <Redirect to='/' />} />
        </Switch>
        <ModalNotification />
      </BrowserRouter>
    </Suspense>
  )
}

export default App
