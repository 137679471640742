import { message, Spin } from 'antd'
import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { checkUserData } from 'services/curl/Auth'
import { addAffiliate } from 'services/curl/Axios'
import { setModalNotification } from 'state/modal/actions'
import styled from 'styled-components'
import GreenButton from './GreenButton'
import Modal from './Modal'
import { formatCode, isTonAddress } from 'helpers/CommonHelper'
import { AppState } from 'state'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Skeleton } from 'widgets/Skeleton'
import useUserService from 'services/curl/User'
import { setMyAffiliate } from 'state/user/actions'

const RecoverInviterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  input {
    background-color: #fff2d1;
    border: none;
    border-radius: 0.5rem;
    width: 100%;
    padding: 0.8rem 1rem;
    outline: none;

    ::placeholder {
      color: #d48941;
    }
  }

  .description {
    padding: 0 0.5rem;
    color: #c15a00;
    font-family: Jua;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`

const InviteButton = styled.button`
  all: unset;
  font-size: 1.2rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -5rem;

  :disabled {
    opacity: 0.7;
  }
`

interface RecoverInviterProps {
  show: boolean
  onClose: () => void
}

const RecoverInviter: FC<RecoverInviterProps> = ({ show, onClose }) => {
  const dispatch = useDispatch()
  const { fetchAffiliate } = useUserService()
  const { myAffiliate } = useSelector((state: AppState) => state.user)

  const userData = checkUserData()
  const [refAddress, setRefAddress] = useState('')
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    setRefAddress('')
    onClose()
  }

  const renderButtonStatus = () => {
    if (!userData?.address) return 'Loading...'
    if (!refAddress) return 'Enter address'
    if (!isTonAddress(refAddress?.trim())) return 'Wrong address'

    return 'Invite a friend'
  }

  const handleConfirm = async () => {
    dispatch(
      setModalNotification({
        toggle: true,
        dataModal: {
          message: 'Recovering',
          buttonDisabled: true,
          loading: true,
        },
      })
    )

    const payload = {
      address: userData?.address,
      refAddress,
    }

    setLoading(true)
    const res = await addAffiliate(payload)
    setLoading(false)

    if (res?.status === 201) {
      if (res?.data?.data?.message === 'Address is existed') {
        dispatch(setModalNotification({ toggle: true, dataModal: { message: 'Your address had referral address' } }))
        handleClose()
        return
      }

      dispatch(setModalNotification({ toggle: true, dataModal: { message: 'Add referral successful' } }))
      await fetchAffiliate(userData?.address, (res) => {
        dispatch(setMyAffiliate(res?.data?.tree || {}))
      })
      handleClose()
    } else {
      dispatch(setModalNotification({ toggle: true, dataModal: { message: 'Add referral failed' } }))
    }
  }

  return (
    <Modal show={show} onClose={handleClose}>
      <div className={'modal-header'}>Recover inviters</div>
      <RecoverInviterWrapper>
        <div className='description'>You can link the account that invited you if this did not happen automatically.</div>
        {!myAffiliate ? (
          <Skeleton height={45} />
        ) : myAffiliate?.refAddress ? (
          <div className='description'>
            Your sponsor address: <br /> {formatCode(myAffiliate?.refAddress, 12, 12)}{' '}
            <CopyToClipboard onCopy={() => message.success('Copied successful', 2)} text={myAffiliate?.refAddress}>
              <svg
                height={16}
                viewBox='0 0 22 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                style={{ position: 'relative', top: 2, marginLeft: 8 }}
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M5.61013 3.37387C5.61013 2.52013 6.29452 1.82804 7.13875 1.82804H14.5503V5.34129C14.5503 6.86767 15.7739 8.10505 17.2832 8.10505H19.9236V18.4574C19.9236 19.3112 19.2392 20.0033 18.395 20.0033H7.13875C6.29452 20.0033 5.61013 19.3112 5.61013 18.4574V3.37387ZM15.7546 5.34129V2.04977L19.8146 6.88712H17.2832C16.439 6.88712 15.7546 6.19503 15.7546 5.34129ZM4.41805 3.11011C4.54937 1.70745 5.71734 0.610107 7.13875 0.610107H16.126L21.128 6.56985V18.4574C21.128 19.9838 19.9044 21.2212 18.395 21.2212H16.836C16.6949 22.6253 15.5143 23.7212 14.0788 23.7212H2.89931C1.36872 23.7212 0.12793 22.4753 0.12793 20.9385V5.89284C0.12793 4.35598 1.36872 3.11011 2.89931 3.11011H4.41805ZM15.6033 21.2212H7.13875C5.62936 21.2212 4.40576 19.9838 4.40576 18.4574V4.3364H2.89931C2.04321 4.3364 1.34921 5.03324 1.34921 5.89284V20.9385C1.34921 21.7981 2.04321 22.4949 2.89931 22.4949H14.0788C14.8387 22.4949 15.4709 21.9458 15.6033 21.2212Z'
                  fill='#24550B'
                />
              </svg>
            </CopyToClipboard>
          </div>
        ) : (
          <input
            autoComplete='off'
            name='address'
            disabled={loading}
            value={refAddress}
            type={'text'}
            placeholder='Inviter account address'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setRefAddress(event.target.value)}
          />
        )}
      </RecoverInviterWrapper>
      {!myAffiliate?.refAddress && (
        <InviteButton disabled={loading || renderButtonStatus() !== 'Invite a friend'} onClick={handleConfirm}>
          <GreenButton>
            {renderButtonStatus()} {loading && <Spin />}
          </GreenButton>
        </InviteButton>
      )}
    </Modal>
  )
}

export default RecoverInviter
