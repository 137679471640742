import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import useUserService from 'services/curl/User'
import { setModalNotification } from 'state/modal/actions'
import { setUserInfo } from 'state/user/actions'
import styled from 'styled-components'
import Modal from './Modal'

const CredentialsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  input {
    background-color: #fff2d1;
    border: none;
    border-radius: 0.5rem;
    width: 100%;
    padding: 0.8rem 1rem;
    outline: none;

    ::placeholder {
      color: #d48941;
    }
  }

  .description {
    padding: 0 0.5rem;
    color: #c15a00;
    font-family: Jua;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .dog-btns {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
  }
`

const DogButton = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin: 1rem 0 0;
  box-shadow: none;
  width: 100px;

  font-size: 1.2rem;
  color: #fff;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #d18d00;
  font-family: Jua;
  font-style: normal;
  font-weight: 400;
  line-height: 0;
  white-space: nowrap;

  position: relative;

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    gap: 5px;

    span {
      margin-top: 2px;
    }
  }
`

interface ModalUpdateProfileProps {
  show: boolean
  onClose: () => void
  userInfo: any
}

const ModalUpdateProfile: FC<ModalUpdateProfileProps> = ({ show, onClose, userInfo }) => {
  const dispatch = useDispatch()
  const { fetchUpdateProfile, fetchMyProfile } = useUserService()
  const [name, setName] = useState('')

  const handleClose = () => {
    setName('')
    onClose()
  }

  const handleConfirm = async () => {
    dispatch(
      setModalNotification({
        toggle: true,
        dataModal: {
          message: 'Updating',
          buttonDisabled: true,
          loading: true,
        },
      })
    )

    await fetchUpdateProfile(
      {
        fullname: name,
      },
      async () => {
        await fetchMyProfile((res) => dispatch(setUserInfo(res?.data)))
        dispatch(setModalNotification({ toggle: true, dataModal: { message: 'Update success!!!' } }))
      },
      () => {
        dispatch(setModalNotification({ toggle: true, dataModal: { message: 'Update failed' } }))
      }
    )
  }

  return (
    <Modal show={show} onClose={handleClose}>
      <div className={'modal-header'}>Update Profile</div>
      <CredentialsWrapper>
        <div className='description'>Account name</div>
        <input
          autoComplete='off'
          name='name'
          value={name}
          type={'text'}
          placeholder={userInfo?.fullname || 'Enter your name'}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
        />

        <div className={'dog-btns'}>
          <DogButton onClick={handleClose}>
            <img src='/images/home/gold-btn.png' alt='View' />
            <div>
              <span>Cancel</span>
            </div>
          </DogButton>
          <DogButton disabled={!name} onClick={handleConfirm}>
            <img src='/images/home/green-btn.png' alt='View' />
            <div>
              <span>Confirm</span>
            </div>
          </DogButton>
        </div>
      </CredentialsWrapper>
    </Modal>
  )
}

export default ModalUpdateProfile
